import PostRow from 'shared-components/posts/PostRow'
import PostCard from 'shared-components/posts/PostCard'
import React from 'react'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'

interface DoubleListingLeftProps extends BasicStyledComponent {
  posts: DSPostShortWithAuthor[]
}

const DoubleListingLeft: React.VFC<DoubleListingLeftProps> = ({ posts, className }) => (
  <div className={className}>
    {posts.map((item, index) => {
      if (index < 1) {
        return <PostCard post={item} key={index} />
      }
      return <PostRow key={index} post={item} />
    })}
  </div>
)

export default DoubleListingLeft
