import styles from './DoubleListing.module.css'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import React, { useCallback } from 'react'
import clsx from 'clsx'
import {
  BasicStyledComponent,
  DSPaginatedList,
  DSPostShortWithAuthor,
} from 'shared-definitions/types'
import DoubleListingLeft from 'shared-components/posts/DoubleListingLeft'
import DoubleListingRight from 'shared-components/posts/DoubleListingRight'

type Double<T> = { left: T; right: T }
type DoubleList = Double<DSPostShortWithAuthor[]>

interface DoubleListingProps extends BasicStyledComponent {
  initial: DoubleList
  loader: (page: number, perPage: number) => Promise<DoubleList[]>
}

const DoubleListing: React.VFC<DoubleListingProps> = ({ className, initial, loader }) => {
  const initialList: DSPaginatedList<DoubleList> = {
    resource: 'paginated-list',
    perPage: 1,
    currentPage: 1,
    totalItems: Infinity,
    items: [initial],
  }
  return (
    <LoadMoreList
      autoload
      render={useCallback(
        (item: DoubleList, index) => (
          <React.Fragment key={index}>
            <DoubleListingLeft posts={item.left} className={styles.subList} />
            <DoubleListingRight posts={item.right} className={styles.subList} />
          </React.Fragment>
        ),
        []
      )}
      className={clsx(className, styles.list)}
      buttonClassName={styles.loadMore}
      initial={initialList}
      loader={loader}
    />
  )
}

export default DoubleListing
