/* eslint-disable react/display-name */
import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './PostCard.module.css'
import AppImage from 'shared-components/ui/AppImage'
import PostedByContainer from 'shared-components/posts/PostedByContainer'
import PostCardUI from 'shared-components/posts/PostCardUI'
import React from 'react'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface PostCardProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
}

const PostCard: React.VFC<PostCardProps> = React.memo(({ post, className }) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <Linked
      className={clsx(styles.container, className)}
      onClick={() => {
        appEvent({
          event: 'widget-interaction',
          widgetType: 'Post Widget',
          label: post.title,
          url: post.pLink.href,
        })
      }}
      {...post.pLink}
    >
      {post.image ? (
        <AppImage
          layout="fill"
          sizes={[{ bp: 'lg', val: 595 }, 'calc(100vw - rem(30))']}
          image={post.image}
        />
      ) : null}
      <div className={styles.description}>
        <PostedByContainer
          post={post}
          className={styles.postedBy}
          dateClassName={styles.postedByDate}
        />
        <div className={styles.title}>{post.title}</div>
      </div>
      <PostCardUI post={post} spaced />
    </Linked>
  )
})

export default PostCard
