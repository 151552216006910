import { BasicStyledComponent, DSTeamMemberShort } from 'shared-definitions/types'
import styles from './PostedBy.module.css'
import stylesCommon from './PostedBy.PostedBySponsor.module.css'
import clsx from 'clsx'

interface PostedByProps extends BasicStyledComponent {
  author: DSTeamMemberShort
  date?: string
  dateClassName?: string
}

const PostedBy: React.VFC<PostedByProps> = ({ author, date, dateClassName, className }) => (
  <div className={clsx(stylesCommon.container, styles.whiteStyle, className)}>
    {author.name}
    {date ? (
      <span className={clsx(styles.date, stylesCommon.grayStyle, dateClassName)}>{date}</span>
    ) : null}
  </div>
)

export default PostedBy
