import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import React from 'react'
import PostRowTiny from 'shared-components/posts/PostRowTiny'

interface DoubleListingRightProps extends BasicStyledComponent {
  posts: DSPostShortWithAuthor[]
}

const DoubleListingRight: React.VFC<DoubleListingRightProps> = ({ className, posts }) => (
  <div className={className}>
    {posts.map((item, index) => (
      <PostRowTiny key={index} post={item} />
    ))}
  </div>
)

export default DoubleListingRight
