import { MutableRefObject, useEffect, useRef } from 'react'

export function useSavedValue<T>(input: T): MutableRefObject<T> {
  const ref = useRef(input)

  useEffect(() => {
    ref.current = input
  }, [input])

  return ref
}
