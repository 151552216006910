import { useState, useEffect, useRef } from 'react'
import { DSPaginatedList } from 'shared-definitions/types'

interface PaginatedProps<T> {
  currentPage: number
  posts: T[]
  loading: boolean
  loadMore: () => void
  complete: boolean
}

type LoaderProps<T> = (page: number, perPage: number) => Promise<T[]>

export function useLoadMore<T>(
  initial: DSPaginatedList<T>,
  loader: LoaderProps<T>
): PaginatedProps<T> {
  const [currentPage, setCurrentPage] = useState(initial.currentPage)
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<T[]>(initial.items)
  const handler = useRef<LoaderProps<T>>()

  useEffect(() => {
    handler.current = loader
  }, [loader])

  useEffect(() => {
    setCurrentPage(initial.currentPage)
    setLoading(false)
    setPosts(initial.items)
  }, [initial])

  useEffect(() => {
    const { current: l } = handler
    if (!loading || !l) {
      return
    }

    void (async () => {
      const newPosts = await l(currentPage, initial.perPage)
      setPosts(oldPosts => [...oldPosts, ...newPosts])
      setLoading(false)
    })()
  }, [loading, currentPage, initial, loader])

  return {
    complete: Math.ceil(initial.totalItems / initial.perPage) <= currentPage,
    posts,
    currentPage,
    loading,
    loadMore: () => {
      setCurrentPage(s => s + 1)
      setLoading(true)
    },
  }
}
