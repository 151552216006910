import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './PostedBy.PostedBySponsor.module.css'
import clsx from 'clsx'

interface PostedBySponsorProps extends BasicStyledComponent {
  sponsor: string
}

const PostedBySponsor: React.VFC<PostedBySponsorProps> = ({ sponsor, className }) => (
  <div className={clsx(className, styles.container, styles.grayStyle)}>{sponsor}</div>
)

export default PostedBySponsor
