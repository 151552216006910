import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import clsx from 'clsx'
import PostedBy from 'shared-components/posts/PostedBy'
import PostedBySponsor from 'shared-components/posts/PostedBySponsor'

interface PostedByContainerProps extends BasicStyledComponent {
  dateClassName?: string
  post: Pick<DSPostShortWithAuthor, 'author' | 'sponsor' | 'date'>
}

const PostedByContainer: React.VFC<PostedByContainerProps> = ({
  className,
  dateClassName,
  post: { author, date, sponsor },
}) => {
  if (author && !sponsor) {
    return (
      <PostedBy dateClassName={dateClassName} className={className} author={author} date={date} />
    )
  }

  if (sponsor) {
    return <PostedBySponsor sponsor={sponsor} className={clsx(className, dateClassName)} />
  }

  return null
}

export default PostedByContainer
