import { useEffect, useRef } from 'react'
import { useSavedValue } from '../hooks/use-saved-value'

interface VisibleMarkerProps {
  onVisible: () => void
  disabled?: boolean
}

const VisibleMarker: React.VFC<VisibleMarkerProps> = ({ disabled, onVisible }) => {
  const ref = useRef<HTMLDivElement>(null)
  const handler = useSavedValue(onVisible)

  useEffect(() => {
    if (disabled) {
      return
    }

    const o = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          handler.current()
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }
    )

    if (ref.current) {
      o.observe(ref.current)
    }

    return () => {
      o.disconnect()
    }
  }, [disabled])

  if (disabled) {
    return null
  }

  return <div hidden ref={ref} />
}

export default VisibleMarker
