import styles from './PostCardUI.module.css'
import clsx from 'clsx'
import { DSPostShort } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import dynamic from 'next/dynamic'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'

const ScoreLine = dynamic(() => import('shared-components/ui/Score'))
const ScoreRing = dynamic(() => import('shared-components/ui/ScoreRing'))

interface PostCardUIProps {
  post: Pick<DSPostShort, 'badge' | 'score'>
  spaced?: boolean
}

const PostCardUI: React.VFC<PostCardUIProps> = ({ post, spaced }) => {
  const { postPreviewScore } = UIVariantContext.useContainer()
  return (
    <>
      {post.score && postPreviewScore === 'line' ? (
        <ScoreLine
          className={clsx(styles.scoreLine, { [styles.spaced]: spaced })}
          score={post.score}
        />
      ) : null}
      {post.score && postPreviewScore === 'ring' ? (
        <ScoreRing
          className={clsx(styles.scoreRing, { [styles.spaced]: spaced })}
          score={post.score}
        />
      ) : null}
      {post.badge ? (
        <AppImage
          image={post.badge}
          imageClassName={styles.badgeImage}
          className={clsx(styles.badge, { [styles.spaced]: spaced })}
          sizes={[{ bp: 'md', val: 72 }, 40]}
          layout="raw"
        />
      ) : null}
    </>
  )
}

export default PostCardUI
